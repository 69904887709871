<template>
  <PosterSlot #default="{ contentClicked }">
    <Swiper
      :navigation="true"
      :modules="[ Navigation, Scrollbar ]"
      :breakpoints="commonBreakPoints"
      :slides-per-view="posterSlidesPerView"
      :space-between="spaceBetween"
      :initial-slide="getSlideKey"
      @slide-change="setSlideKey"
      class="more-swiper"
      :scrollbar="{
        hide: true,
        draggable: true,
        dragSize: 40,
        horizontalClass: 'scrollbar-white'
      }"
    >
      <SwiperSlide
        v-for="content in posters"
        :key="content.film_id || content.channel_id || content.id"
      >
        <PosterCard
          :content="content"
          :collection-id="content.time_start || localStorageKey"
          @click="contentClicked(content, collectionId || content.time_start || localStorageKey)"
        />
      </SwiperSlide>
    </Swiper>
  </PosterSlot>
</template>

<script setup>
// packages
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Scrollbar } from 'swiper';
// components
import PosterSlot from '@/components/ui/Posters/PosterSlot.vue';
import PosterCard from '@/components/ui/Posters/PosterCard.vue';
import { commonBreakPoints } from '@/components/ui/breakPoints';

const props = defineProps({
  posters: {
    type: Array,
    default: () => [],
  },
  localStorageKey: {
    type: [String, Number],
    default: '',
  },
  collectionId: {
    type: Number,
    default: 0,
  },
});

const { posterSlidesPerView, spaceBetween } = storeToRefs(usePosterDetailsStore());
const slideKey = useLocalStorage('sliderKey', { [props.localStorageKey]: 0 });
const setSlideKey = ({ activeIndex }) => slideKey.value = { [props.localStorageKey]: activeIndex };
const getSlideKey = useLocalStorage([props.localStorageKey], 0);
</script>
